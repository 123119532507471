<template>
  <div class="blur-box">
    <p class="text-center pt-4 pb-10 font-medium text-white">فراموشی رمز عبور</p>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <validation-provider name="کد تایید" rules="required|min:6" v-slot="{ errors }">
          <label class="block">
            <span class="text-white opacity-50 text-sm">.کد پیامک شده به شماره <span class="font-bold">{{ phone }}</span> را وارد کنید</span>
            <input
                type="number"
                pattern="\d*"
                class="mt-0 block text-white w-full py-3 px-0.5 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 "
                v-model="code"
                placeholder="کد تایید اارسال شده"
            >
          </label>
        </validation-provider>
        <validation-provider name="رمز عبور"  rules="required|min:9|confirmed:confirmation" v-slot="{ errors } " >
          <label class="block relative mt-7">
            <span class="text-white opacity-50 text-sm">رمز عبور</span>
            <input
                v-model="password"
                :type="passwordVisible ? 'text' : 'password'"
                class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white">
            <span class="absolute top-8" @click="passwordVisible = !passwordVisible">
          <svg v-if="!passwordVisible" id="eye-view" xmlns="http://www.w3.org/2000/svg" width="16.404" height="14.248"
               viewBox="0 0 16.404 14.248">
            <g id="Group_86" data-name="Group 86" transform="translate(0 1.749)">
              <path id="Path_160" data-name="Path 160"
                    d="M14.136,97.074A8.568,8.568,0,0,1,8,99.552a8.607,8.607,0,0,1-6.139-2.478,8.956,8.956,0,0,1-1.812-2.5.54.54,0,0,1,0-.451,7.655,7.655,0,0,1,1.765-2.347,8.944,8.944,0,0,1,6.242-2.3,8.747,8.747,0,0,1,6.186,2.309,7.255,7.255,0,0,1,1.718,2.356.532.532,0,0,1-.009.432A8.73,8.73,0,0,1,14.136,97.074Zm-6.054-6.43a2.741,2.741,0,1,0,2.741,2.741A2.744,2.744,0,0,0,8.082,90.645Z"
                    transform="translate(0.002 -89.481)" fill="#ff7500"/>
            </g>
            <path id="Path_2136" data-name="Path 2136" d="M0,16.615H1.352L2.059,0H.707Z"
                  transform="translate(4.656 13.204) rotate(-135)" fill="#fff"/>
            <path id="Path_2137" data-name="Path 2137" d="M0,18.091H1.352L2.059,0H.707Z"
                  transform="translate(2.007 14.248) rotate(-135)" fill="#ff7500"/>
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="10.071" viewBox="0 0 16 10.071">
  <path id="Path_160" data-name="Path 160"
        d="M14.136,97.074A8.568,8.568,0,0,1,8,99.552a8.607,8.607,0,0,1-6.139-2.478,8.956,8.956,0,0,1-1.812-2.5.54.54,0,0,1,0-.451,7.655,7.655,0,0,1,1.765-2.347,8.944,8.944,0,0,1,6.242-2.3,8.747,8.747,0,0,1,6.186,2.309,7.255,7.255,0,0,1,1.718,2.356.532.532,0,0,1-.009.432A8.73,8.73,0,0,1,14.136,97.074Zm-6.054-6.43a2.741,2.741,0,1,0,2.741,2.741A2.744,2.744,0,0,0,8.082,90.645Z"
        transform="translate(0.002 -89.481)" fill="#ff7500"/>
</svg>
        </span>
          </label>
          <span class="text-sm text-red-500 text-right inline-block ">{{ errors[0] }}</span>
        </validation-provider>
        <validation-provider name="تکرار رمز عبور"  v-slot="{ errors }" vid="confirmation">
          <label class="block relative mt-7">
            <span class="text-white opacity-50 text-sm">تکرار رمز عبور</span>
            <input
                v-model="confirmation"
                :type="repeatPasswordVisible ? 'text' : 'password'"
                class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white">
            <span class="absolute top-8" @click="repeatPasswordVisible = !repeatPasswordVisible">
          <svg v-if="!repeatPasswordVisible" id="eye-view" xmlns="http://www.w3.org/2000/svg" width="16.404"
               height="14.248" viewBox="0 0 16.404 14.248">
            <g id="Group_86" data-name="Group 86" transform="translate(0 1.749)">
              <path id="Path_160" data-name="Path 160"
                    d="M14.136,97.074A8.568,8.568,0,0,1,8,99.552a8.607,8.607,0,0,1-6.139-2.478,8.956,8.956,0,0,1-1.812-2.5.54.54,0,0,1,0-.451,7.655,7.655,0,0,1,1.765-2.347,8.944,8.944,0,0,1,6.242-2.3,8.747,8.747,0,0,1,6.186,2.309,7.255,7.255,0,0,1,1.718,2.356.532.532,0,0,1-.009.432A8.73,8.73,0,0,1,14.136,97.074Zm-6.054-6.43a2.741,2.741,0,1,0,2.741,2.741A2.744,2.744,0,0,0,8.082,90.645Z"
                    transform="translate(0.002 -89.481)" fill="#ff7500"/>
            </g>
            <path id="Path_2136" data-name="Path 2136" d="M0,16.615H1.352L2.059,0H.707Z"
                  transform="translate(4.656 13.204) rotate(-135)" fill="#fff"/>
            <path id="Path_2137" data-name="Path 2137" d="M0,18.091H1.352L2.059,0H.707Z"
                  transform="translate(2.007 14.248) rotate(-135)" fill="#ff7500"/>
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="10.071" viewBox="0 0 16 10.071">
  <path id="Path_160" data-name="Path 160"
        d="M14.136,97.074A8.568,8.568,0,0,1,8,99.552a8.607,8.607,0,0,1-6.139-2.478,8.956,8.956,0,0,1-1.812-2.5.54.54,0,0,1,0-.451,7.655,7.655,0,0,1,1.765-2.347,8.944,8.944,0,0,1,6.242-2.3,8.747,8.747,0,0,1,6.186,2.309,7.255,7.255,0,0,1,1.718,2.356.532.532,0,0,1-.009.432A8.73,8.73,0,0,1,14.136,97.074Zm-6.054-6.43a2.741,2.741,0,1,0,2.741,2.741A2.744,2.744,0,0,0,8.082,90.645Z"
        transform="translate(0.002 -89.481)" fill="#ff7500"/>
</svg>
        </span>
          </label>
          <span class="text-sm text-red-500 text-right inline-block ">{{ errors[0] }}</span>
        </validation-provider>
        <button type="submit" class="btn-custom mt-11 w-full">تغییر رمز عبور
          <span class="svg-btn-icon bg-orange">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import axios from "../../plugins/axios";

export default {
  name: "ChangePassword",
  data(){
    return{
      passwordVisible : false,
      password : '',
      code : '',
      phone : this.$store.state.auth.forget.phone
    }
  },
  methods: {
    onSubmit(){
      axios.post('/password-reset',{
        phone : this.$store.state.auth.forget.phone,
        otp : this.code,
        password : this.password
      }).then(({data})=>{
        this.$toast.success('پسورد شما با موفقیت تغییر کرد لطفا وارد شوید');
        this.$router.push({name : "RealEstateLogin"})
      })
    }
  }
}
</script>

<style scoped>

</style>