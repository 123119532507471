<template>
  <div class="blur-box">
    <p class="text-center pt-4 pb-10 font-medium text-white">فراموشی رمز عبور</p>
    <label class="block">
      <span class="text-white opacity-50">شماره همراه</span>
      <input
          type="number"
          pattern="\d*"
          class="mt-0 block text-white w-full py-4 px-0.5 border-0 border-b-2 opacity-50 focus:opacity-100  focus:ring-0 "
          v-model="phone"
          :class="isError ? 'border-red-500 focus:border-red-500' : 'border-white focus:border-white' "
      >
      <span
          v-if="isError"
          class="text-sm text-red-500 text-right inline-block mt-1"
      >{{error}}</span>
    </label>
    <a @click.prevent="sendCode" class="btn-custom mt-9" >درخواست تایید کد</a>
    <p class="text-white text-xs text-center mt-11">حساب کاربری داری؟<router-link :to="{name : 'RealEstateLogin'}" class="text-yellow-400 text-base">وارد شو</router-link></p>
  </div>
</template>

<script>
import axios from "../../plugins/axios";

export default {
name: "PhoneCheck",
  data(){
    return{
      phone : '',
      error : null,
      isError : false
    }
  },

  methods :{
    sendCode(){
      axios.post('/verification/verify-real-estate-phone',{
        phone : this.phone
      }).then(({ data }) => {
            if (data.status === true){
               this.$store.commit('auth/SET_FORGET_PHONE' , this.phone)
               this.$emit('phone-check' , true)
            }
          })
          .catch((error)=>{
            this.isError = true;
            this.error = error.message
          })
    }
  }
}
</script>

<style scoped>

</style>