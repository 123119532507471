<template>
  <div class="flex">
    <div class="bg-mask"></div>
    <div class="bg-login"></div>
    <div class="m-auto mt-52 w-1/2 z-10 ">
      <img src="../assets/images/logo/logo.png" alt="">
    </div>
    <PhoneCheck @phone-check="checkPhone" v-if="!isPhoneSend"/>
    <ForgetPassword v-else />
  </div>
</template>

<script>
import PhoneCheck from "../components/forgetPassword/PhoneCheck";
import ForgetPassword from "../components/forgetPassword/ChangePassword"

export default {
name: "RealEstateForgetPassword",
  components :{
    PhoneCheck,
    ForgetPassword
  },
  data(){
    return{
      isPhoneSend : false,
      isVerify : false,
    }
  },
  methods:{
    checkPhone(data){
      this.isPhoneSend = data
    },
    checkVerify(data){
      this.isVerify = data
    }
  },
}
</script>

<style scoped>

</style>